<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding">
            <b-row>
              <!-- item source -->
              <b-col md="12">
                <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-start">
                  <h4 class="mb-1 mb-md-3">
                    Orden del menú para mostrar en <span class="text-primary font-weight-bolder">{{ data.country.label }}</span>
                  </h4>

                  <a
                    class="text-right text-md-center align-self-end align-self-md-start"
                    style="width: 120px; cursor: pointer;"
                    @click.prevent.stop="applyOrderAll"
                  >
                    Aplicar orden a todos los paises
                  </a>
                </div>

                <b-card
                  class="mb-2"
                  style="max-width: 440px; width: 100%; margin: 0 auto;"
                >
                  <draggable :list="data.links">
                    <div
                      v-for="(item, index) in data.links"
                      :key="index"
                      class="d-flex align-items-center mb-50"
                    >
                      <b-list-group-item
                        :class="[
                          'd-flex align-items-center justify-content-between border-1 border-dark rounded-sm cursor-move flex-grow-1 mr-1',
                          skin === 'dark' ? 'bg-transparent' : 'bg-white',
                        ]"
                      >
                        <span>{{ item.label }}</span>

                        <span>
                          <template v-if="!item.device">
                            <feather-icon
                              :id="`${item.id}-slash-icon`"
                              icon="SlashIcon"
                              size="16"
                              class="mr-1"
                              color="#ea5455"
                            />
                            <b-tooltip
                              title="Configurar dispositivo"
                              :target="`${item.id}-slash-icon`"
                            />
                          </template>

                          <template v-if="item.device === 'all' || item.device === 'desktop'">
                            <feather-icon
                              :id="`${item.id}-desktop-icon`"
                              icon="MonitorIcon"
                              size="16"
                              class="mr-1"
                              color="#7367f0"
                            />
                            <b-tooltip
                              title="Visible en desktop"
                              :target="`${item.id}-desktop-icon`"
                            />
                          </template>

                          <template v-if="item.device === 'all' || item.device === 'mobile'">
                            <feather-icon
                              :id="`${item.id}-mobile-icon`"
                              icon="SmartphoneIcon"
                              size="16"
                              class="mr-1"
                              color="#7367f0"
                            />
                            <b-tooltip
                              title="Visible en mobile"
                              :target="`${item.id}-mobile-icon`"
                            />
                          </template>

                          <app-image
                            :src="item.icon"
                            alt="Image"
                            style="display: inline-block;"
                            blank-color="transparent"
                          />
                        </span>
                      </b-list-group-item>

                      <div class="d-flex position-relative">
                        <b-dropdown
                          size="sm"
                          variant="transparent"
                          split
                        >
                          <template #button-content>
                            <feather-icon
                              :id="`${item.id}-visible-icon`"
                              :icon="item.visible ? 'EyeIcon' : 'EyeOffIcon'"
                              size="18"
                              class="cursor-pointer"
                              @click="item.visible = !item.visible"
                            />
                            <b-tooltip
                              :title="item.visible ? 'Ocultar link' : 'Mostrar link'"
                              :target="`${item.id}-visible-icon`"
                            />
                            <div
                              v-if="item.require_auth"
                              class="position-absolute bg-primary rounded-circle d-flex align-items-center justify-content-center"
                              style="top:-5px;right:-5px;width: 18px;height: 18px;"
                            >
                              <feather-icon
                                :id="`${item.id}-require_auth-icon`"
                                icon="ShieldIcon"
                                size="12"
                                class="cursor-pointer text-white"
                                @click="item.require_auth = !item.require_auth"
                              />
                              <b-tooltip
                                title="Requiere autenticación"
                                :target="`${item.id}-require_auth-icon`"
                              />
                            </div>
                          </template>
                          <b-dropdown-item
                            href="#"
                            @click="item.visible = !item.visible"
                          >
                            {{ item.visible ? 'Ocultar' : 'Visible' }}
                          </b-dropdown-item>
                          <b-dropdown-item
                            href="#"
                            @click="item.require_auth = !item.require_auth"
                          >
                            {{ item.require_auth ? 'No Requerir autenticación' : 'Requerir autenticación' }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </draggable>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <div class="mb-2">
            <b-form-group
              label="País"
              label-for="input-country"
            >
              <v-select
                v-model="data.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :disabled="loadingSubmit"
                input-id="input-country"
                @input="loadLinks"
              />
            </b-form-group>

            <hr class="invoice-spacing">
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            @click="onSubmit"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y publicar
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroupItem,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import AppImage from '@core/components/app-image/AppImage.vue'
import useAppConfig from '@core/app-config/useAppConfig'

import useCountries from '../countries/useCountries'
import useHeader from './useHeader'

export default {
  components: {
    draggable,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BTooltip,
    BSpinner,
    vSelect,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    AppImage,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig()
    const loading = ref(false)
    const loadingSubmit = ref(false)
    const data = ref({
      country: '',
      links: [],
    })
    const { fetchLinksOrder, saveLinksOrder, saveApplyOrderAll } = useHeader()
    const { fetchCountriesSelector } = useCountries()
    const countries = ref([])

    const loadLinks = async () => {
      try {
        const links = await fetchLinksOrder(data.value.country.id)

        data.value = {
          ...data.value,
          links,
        }
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      const countryList = await fetchCountriesSelector()

      countries.value = countryList
      data.value = {
        ...data.value,
        country: countryList[0],
      }

      await loadLinks()
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true

      try {
        const form = {
          country_id: data.value.country.id,
          links: data.value.links.map((item, index) => ({
            id: item.id,
            order: index,
            visible: item.visible,
            require_auth: item.require_auth ?? false,
          })),
        }

        await saveLinksOrder(form)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    async function applyOrderAll() {
      if (loading.value) return
      loading.value = true

      try {
        const form = {
          country_id: data.value.country.id,
          links: data.value.links.map((item, index) => ({
            id: item.id,
            order: index,
            visible: item.visible,
            require_auth: item.require_auth,
          })),
        }

        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, continuar!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          await saveApplyOrderAll(form)
        }
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      loadingSubmit,
      onSubmit,
      applyOrderAll,
      data,
      countries,
      loadLinks,
      skin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
